import { ref, computed, onMounted } from '@vue/composition-api'
import router from '@/router'
import axios from '@axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePositionForm() {
  const positionId = router.currentRoute.params.id

  // Notification
  const toast = useToast()

  // variable input
  const name = ref(null)
  const nameEng = ref(null)

  // loading animation
  const overlaySubmit = ref(false)

  const fncSubmit = () => {
    overlaySubmit.value = true

    const payload = {
      position_id: positionId,
      position_desc: name.value,
      position_desc_en: nameEng.value,
    }

    if (!positionId) delete payload.position_id

    if (!positionId) {
      axios
        .post('/Position', { requestData: payload })
        .then(() => {
          router.push('/position')

          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'บันทึกข้อมูลตำแหน่งสำเร็จ',
            },
          })

          overlaySubmit.value = false
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              text: `พบข้อผิดพลาดในการบันทึกข้อมูล [${error}]`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          overlaySubmit.value = false
        })
    } else {
      axios
        .put('/Position', { requestData: payload })
        .then(() => {
          router.push('/position')

          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'แก้ไขข้อมูลตำแหน่งสำเร็จ',
            },
          })

          overlaySubmit.value = false
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              text: `พบข้อผิดพลาดในการแก้ไขข้อมูล [${error}]`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          overlaySubmit.value = false
        })
    }
  }

  const fncFetchDataEdit = () => {
    axios
      .get(`/Position/${positionId}`)
      .then(response => {
        const { responseData } = response.data

        if (response.data.status) {
          name.value = responseData.position_desc
          nameEng.value = responseData.position_desc_en
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              text: `พบข้อผิดพลาด [${responseData.detail}]`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาด [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  /* กำหนดรูปแบบ ว/ด/ป */
  /* กำหนดปีเป็น พ.ศ. */
  const toBuddhistYear = (val, format) => {
    const christianYear = val.format('YYYY')
    // eslint-disable-next-line radix
    const buddhishYear = (parseInt(christianYear) + 543).toString()
    return val
      .format(
        format
          .replace('YYYY', buddhishYear)
          .replace('YY', buddhishYear.substring(2, 4)),
      )
      .replace(christianYear, buddhishYear)
  }

  const resolveFormatDate = val => (val
    ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
    : null)

  // helper
  const isLocalServer = computed(() => {
    const getServerName = window.location.host.includes(':') ? window.location.host.split(':')[0] : window.location.host
    return getServerName === 'localhost'
  })

  onMounted(() => {
    if (positionId) fncFetchDataEdit()
  })

  return {
    positionId,

    // variable input
    name,
    nameEng,

    // loading animation
    overlaySubmit,

    // function
    fncSubmit,
    resolveFormatDate,

    // helper
    isLocalServer,
  }
}
