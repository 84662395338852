<template>
  <b-card
    :title="[!positionId ? 'เพิ่ม' : 'แก้ไข'] + 'ข้อมูลตำแหน่ง'"
    class="form-position"
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(fncSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    ชื่อตำแหน่ง <span class="text-danger required-font-style">*</span>
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      trim
                      placeholder="ชื่อ"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอกชื่อตำแหน่ง' : '' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    ชื่อตำแหน่ง (ภาษาอังกฤษ)
                  </template>
                  <b-form-input
                    id="nameEng"
                    v-model="nameEng"
                    trim
                    placeholder="ชื่อตำแหน่ง (ภาษาอังกฤษ)"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-overlay
                      :show="overlaySubmit"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                      class="d-inline-block"
                    >
                      <b-button
                        variant="primary"
                        class="mr-2"
                        type="submit"
                      >
                        <feather-icon icon="SaveIcon" />
                        บันทึกข้อมูล
                      </b-button>
                    </b-overlay>
                    <b-button
                      type="button"
                      variant="outline-secondary"
                      :to="{ path: '/position' }"
                    >
                      ยกเลิก
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BOverlay, BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import usePositionForm from './usePositionForm'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const {
      positionId,

      // variable input
      name,
      nameEng,

      // loading animation
      overlaySubmit,

      // function
      fncSubmit,
      resolveFormatDate,

      // helper
      isLocalServer,
    } = usePositionForm()

    // vee validate
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      positionId,

      // variable input
      name,
      nameEng,

      // loading animation
      overlaySubmit,

      // function
      fncSubmit,
      resolveFormatDate,

      // helper
      isLocalServer,

      // vee validate
      refFormObserver,
      getValidationState,
      resetForm,
      required,
    }
  },
}
</script>

<style>
.required-font-style
{
  font-weight: bold;
  font-size: 18px;;
}

.form-position .form-row > legend
{
  margin-top: 10px;
}

.form-position .delete-margin {
  margin-top: -40px;
}
</style>
